import React, { getGlobal, useGlobal } from "reactn";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Menu, Dropdown, Button } from "antd";

import { authAPI } from "utils/portal-apis";
import { SignIn } from "components/Auth";

import styled from "styled-components";

export const defaultSettings = {
  logo: "/logo_br.svg",
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "Fixed",
  title: "",
  style: {
    minHeight: "100vh",
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return defaultDom;
    }
    return <a href={menuItemProps.path}>{defaultDom}</a>;
  },
};

export const publicDefaultSettings = {
  logo: "/logo_br.svg",
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "wrap",
  title: "",
  style: {
    minHeight: "100vh",
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return defaultDom;
    }
    return <a href={menuItemProps.path}>{defaultDom}</a>;
  },
};

const StyledDropdown = styled(Dropdown)`
  &.live {
    cursor: default !important;
  }
`;

export const getMenuRoutes = (isLive) => {
  const loggedInUser = getGlobal();
  const isBroadcastPage =
    window.location.href.indexOf("/broadcast/") > 0 && window.location.href.indexOf("/broadcast/my") < 0;
  const isNotAdmin = loggedInUser.role !== "admin";
  const domain = new URL(window.location.href).origin;

  if (isBroadcastPage) {
    return {
      path: "/",
      routes: [
        {
          path: `/broadcast/my`,
          name: "Dashboard",
          target: "_blank",
        },
      ],
    };
  }
  return {
    path: "/",
    routes: [
      {
        path: `/broadcast/my`,
        name: "Dashboard",
      },
      {
        path: "/media",
        name: "Graphics",
        hideInMenu: isBroadcastPage,
      },
      // {
      //   path: `/orgs`,
      //   name: "Organizations",
      //   hideInMenu: loggedInUser.role !== "admin"
      // },
      {
        name: "Producer Tools",
        hideInMenu: isNotAdmin || !(window.location.href.includes("brcreatorstudio")),
        children: [
          {
            path: `${domain}/event/active`,
            name: "Active Events",
            target: isBroadcastPage && isLive ? "_blank" : "_self",
          },
          {
            path: `${domain}/user/list`,
            name: "Users",
            target: isBroadcastPage && isLive ? "_blank" : "_self",
          },
          {
            path: `${domain}/moderation`,
            name: "Moderation",
            target: isBroadcastPage && isLive ? "_blank" : "_self",
            hideInMenu: !(
              loggedInUser._id === "a533e186-6824-43ed-a33e-bbc15ab1b776" ||
              loggedInUser._id === "baa1fc92-1a44-4154-a9ea-117719654941"
            ),
          },
        ],
      },
      {
        path: "https://brcreators.zendesk.com/hc/en-us",
        name: "Help",
        target: "_blank",
      },
      {
        path: "/settings/:name",
        name: "Settings",
        hideInMenu: true,
      },
      {
        path: "/event/:id",
        name: "Event Dashboard",
        hideInMenu: true,
      },
      {
        path: "/broadcast/:id",
        name: "Broadcast Dashboard",
        hideInMenu: true,
      },
    ],
  };
};

export function RightContent({ isPublicPage, showSignIn, signInSetter, isLive }) {
  const loggedInUser = getGlobal();
  const isBroadcastPage =
    window.location.href.indexOf("/broadcast/") > 0 && window.location.href.indexOf("/broadcast/my") < 0;

  const actionSelect = ({ key }) => {
    if (key === "logout") {
      authAPI.logout();
    }
  };

  const menuHeaderDropdown = (
    <Menu onClick={actionSelect}>
      {isPublicPage !== true && (
        <Menu.Item key="settings">
          <a href="/settings">
            <FontAwesomeIcon icon={faCog} className="anticon" fixedWidth /> Settings
          </a>
        </Menu.Item>
      )}
      {isPublicPage !== true && !isBroadcastPage && <Menu.Divider />}
      {!isBroadcastPage && (
        <Menu.Item key="logout">
          {isPublicPage !== true && (
            <a
              href={{
                pathname: "/",
                search: window.location.search,
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
            </a>
          )}
          {isPublicPage === true && (
            <a
              href={{
                search: window.location.search,
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
            </a>
          )}
        </Menu.Item>
      )}
    </Menu>
  );

  if (authAPI.isLoggedIn()) {
    return (
      <StyledDropdown
        overlay={menuHeaderDropdown}
        disabled={isLive}
        // arrow={!isLive}
        className={isLive ? "live" : ""}
      >
        <span style={{ height: "64px", display: "block", cursor: "pointer" }}>
          <Avatar
            src={
              loggedInUser.profile_pic || "https://hotmic-content.s3-us-west-1.amazonaws.com/users/default-profile.png"
            }
          />
          <span style={{ margin: "0 10px" }}>{loggedInUser.name}</span>
        </span>
      </StyledDropdown>
    );
  } else {
    return (
      <span>
        <Button type="primary" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => signInSetter(true)}>
          Sign In
        </Button>
        <SignIn showModal={showSignIn} onCancelled={() => signInSetter(false)} />
      </span>
    );
  }
}
