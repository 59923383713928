import { fetchWrapperPortal } from "./fetch";
import { setGlobal, resetGlobal } from "reactn";

export const signup = async user =>
  await fetchWrapperPortal({
    url: `users/`,
    method: `POST`,
    body: JSON.stringify(user)
  });

export const createOther = async user => {
  return await fetchWrapperPortal({
    url: `users/new`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify(user)
  });
};

export const addToMyOrg = async user => {
  return await fetchWrapperPortal({
    url: `users/myorg/`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify(user)
  });
};

export const update = async (userId, payload) => {
  let res = await fetchWrapperPortal({
    url: `users/` + userId,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(payload)
  });
  if (res.success) {
    persistUser(res.user);
    return res.user;
  } else {
    return null;
  }
};

export const updateOther = async (userId, payload) => {
  let res = await fetchWrapperPortal({
    url: `users/` + userId,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(payload)
  });
  if (res.success) {
    return res.user;
  } else {
    return res;
  }
};

export const del = async userId => {
  let res = await fetchWrapperPortal({
    url: `users/` + userId,
    method: "DELETE",
    addBearer: true
  });
  if (res.success) {
    return res.user;
  } else {
    return null;
  }
};

export const resendInvite = async userId => {
  let res = await fetchWrapperPortal({
    url: `users/` + userId + `/resend-invite`,
    method: "POST",
    addBearer: true
  });
  if (res.success) {
    return true;
  } else {
    return false;
  }
};

export const uploadImage = async (file, type) => {
  console.log("upload", file.type, type);
  const result = await fetchWrapperPortal({
    url: `users/image_upload`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({
      content_type: file.type,
      image_type: type
    })
  });

  return await fetch(result.put_url, {
    method: "PUT",
    body: file
  }).then(e => {
    if (e.ok) {
      console.log("image", result.get_url);
      return result.get_url;
    }
    throw e;
  });
};

export const getStats = async (id, start, end) =>
  await fetchWrapperPortal({
    url: `users/${id}/stats/${start ? `?start=${start}` : ""}${end ? `&end=${end}` : ""}`,
    method: "GET",
    addBearer: true
  });

export const refreshUser = async () => {
  const user = await fetchWrapperPortal({
    url: `users/me`,
    method: `GET`,
    addBearer: true
  });
  persistUser(user);
  return user;
};

export function persistUser(user) {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    setGlobal(user);
  } else {
    resetGlobal();
    localStorage.clear();
  }
}

export const getBanners = async userId =>
  await fetchWrapperPortal({
    url: `users/${userId}/banners/`,
    method: `GET`,
    addBearer: true
  });

export const getProducts = async userId =>
  await fetchWrapperPortal({
    url: `users/${userId}/products/`,
    method: `GET`,
    addBearer: true
  });

export const changePassword = async payload =>
  await fetchWrapperPortal({
    url: "users/me/password/",
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const makeMod = async (broadcaster, moderator) =>
  await fetchWrapperPortal({
    url: `users/${broadcaster}/moderators/${moderator}`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify({ broadcaster, moderator })
  });

export const removeMod = async (broadcaster, moderator) =>
  await fetchWrapperPortal({
    url: `users/${broadcaster}/moderators/${moderator}`,
    method: "DELETE",
    addBearer: true,
    body: JSON.stringify({})
  });

export const sendResetEmail = async email =>
  await fetchWrapperPortal({
    url: `auth/password/reset/`,
    method: "POST",
    body: JSON.stringify({ email: email })
  });

export const setNewPassword = async (token, password) =>
  await fetchWrapperPortal({
    url: `auth/password/reset/${token}`,
    method: "PUT",
    body: JSON.stringify({ password: password })
  });

export const getAll = async () =>
  await fetchWrapperPortal({
    url: `users/`,
    method: `GET`,
    addBearer: true
  });

export const getAllFromMyOrg = async () =>
  await fetchWrapperPortal({
    url: `users/myorg/`,
    method: `GET`,
    addBearer: true
  });

export const getBlockedUsers = async () =>
  await fetchWrapperPortal({
    url: `users/blocked/`,
    method: `GET`,
    addBearer: true
  });

export const setBlockedStatus = async (userId, blockFlag) => {
  try {
    const response = await fetchWrapperPortal({
      url: `users/setBlockedStatus/`,
      method: `POST`,
      addBearer: true,
      body: JSON.stringify({ userId, blockFlag })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("There was a problem with the fetch operation: " + error.message);
  }
};

export const checkCreator = async id =>
  await fetchWrapperPortal({
    url: `users/${id}/link-check`,
    method: "GET",
    addBearer: true
  });
